 
html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }


  /* .border {
    background-color:#82EB72;
    border-radius:(50em 0em 50em 50em / 50em 0em 50em 50em );
    width:30em
} */
/* @media screen  and (max-width:640px){
    .border img {
             width: fit-content;
             height: 300px
    }
}   */

/* @media screen and (max-width: 360px) {
    .border {
    border-radius:(40em 0em 50em 0em / 50em 0em 30em 50em );
    width:20em
        
     }
 }
@media screen and (max-width:640px){
    .border {
        border-radius:(30em 0em 50em 50em / 0em 0em 60em 90em );
        width:20em
    }

}

@media screen and (max-width: 600px) {
 .border {
  
 }
  
  } */


 
 


#span1:before{
    content:'';
    animation:animate infinite 7s;
}

@keyframes animate{
    0%{content:'Groceries'; font-size: 40px;  }
    25%{content:'Medicines'; font-size: 40px;  }
    35%{content:'Dairy'; font-size: 40px;  }
    55%{content:'Fruits'; font-size: 40px;  }
    70%{content:'Vegetables'; font-size: 40px;  }
    85%{content:'Daily Needs'; font-size: 40px;  }
    100%{content:'Groceries'; font-size: 40px;  }
}   